import sanitizeObject from "./sanitizeObject";
window.Buffer = window.Buffer || require("buffer").Buffer;

const loadUrlParams = () => {
  const url = new URL(window.location.href);
  let result = {};

  try {
    const propsParam = url.searchParams.get("props");
    const silent = url.searchParams.get("silent") === "true";
    const preview = url.searchParams.get("preview") === "true";
    const source = url.searchParams.get("source");
    // scenario is related to tip of the day feature, in user dashboard; it can be:
    // 1readagain; 2missed; 3discover [scenario field is populated by user dashboard, based on tipOftheDayUDscenario sent from brain]
    // Another use case: when user clicks on semantic learning material, a simulation request is sent to brain;
    // brain directly enriches the templateUrl with scenario=fromLearningMaterial
    const scenario = url.searchParams.get("scenario");
    const iframeId = url.searchParams.get("iframeId");
    const propsString = Buffer.from(unescape(propsParam), "base64").toString(
      "utf-8"
    );
    const props = sanitizeObject(JSON.parse(propsString));

    console.log(`props.semanticId: ${props.semanticId}`);

    // decode leafPayload too
    const leafPayloadParam = url.searchParams.get("leafPayload");
    let leafPayload = null;
    if (leafPayloadParam) {
      const leafPayloadString = Buffer.from(
        unescape(leafPayloadParam),
        "base64"
      ).toString("utf-8");
      leafPayload = sanitizeObject(JSON.parse(leafPayloadString));
    }

    //== setting delay fields
    const nowTimestamp = Date.now();
    if (leafPayload?.brainRequestTime) {
      leafPayload.delayedDisplayTime = Math.floor(
        (nowTimestamp - leafPayload.brainRequestTime) / 1000
      );
    }
    if (leafPayload?.scenario === "delayed") {
      if (leafPayload?.wentOutOfMeetAt) {
        leafPayload.delayedDisplayTimeSinceCallEnd = Math.floor(
          (nowTimestamp - parseFloat(leafPayload.wentOutOfMeetAt) * 1000) / 1000
        );
      }
    }
    //==

    const { leafVersion } = props;
    const versionRegex = /(\d+)\.(\d+)\.(\d+)(\.(\d+))?/;
    const [, major, minor, patch, build] =
      leafVersion?.match(versionRegex) || [];

    props.leafVersion = major
      ? {
          major: Number(major),
          minor: Number(minor),
          patch: Number(patch),
          build: Number(build),
          raw: leafVersion,
        }
      : null;

    result = {
      ...result,
      props,
      leafPayload,
      silent,
      propsString,
      preview,
      iframeId,
      source,
      scenario,
    };
  } catch (_) {
    result = { ...result, error: true };
  }

  return result;
};

export default loadUrlParams;
