import { keyframes, css } from "styled-components";

const ShakeAnimation = keyframes`
  0.5%,
  2.5%,
  4.5%,
  6.5%,
  8.5% {
    transform: rotate(-3deg);
  }

  1.5%,
  3.5%,
  5.5%,
  7.5%,
  9.5% {
    transform: rotate(3deg);
  }

  10% {
    transform: rotate(0);
  }
`;

export const ShakeAnimationCSS = css`
  transform: rotate(0);
  animation-name: ${ShakeAnimation};
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  &:hover {
    animation-play-state: paused;
  }
`;
