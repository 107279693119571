import { createSelector } from "reselect";

const ModuleState = ({ semantic }) => semantic;

export const selectSemantic = createSelector(
  ModuleState,
  (state) => state.semantic
);

export const selectInitialProps = createSelector(
  ModuleState,
  (state) => state.initialProps
);

export const selectIsExtension = createSelector(
  selectInitialProps,
  ({ leafVersion }) => leafVersion?.raw?.startsWith("hi-web-")
);

export const selectQueryProps = createSelector(
  ModuleState,
  (state) => state.queryProps
);

export const selectSemanticType = createSelector(
  selectSemantic,
  (semantic) => semantic?.type
);

export const selectDropdownValue = createSelector(
  ModuleState,
  (state) => state.dropdownValue
);
