const processSvg = (svgField, desiredColor, changes) => {
  // alter svg

  // currently handling just cases of full svg already available in the field (data:image/svg+xml;utf8,<svg..)
  // Not currently fetching svg by url

  if (svgField && svgField.startsWith('url("data:image/svg+xml;utf8')) {
    changes.forEach((change) => {
      const newColorText =
        desiredColor !== null
          ? `${change.attribute}='${desiredColor.replace("#", "%23")}'`
          : "";
      const sourceRegexp = new RegExp(`<${change.element}([^>]*)>`, "gi");
      const destRegexp = new RegExp(
        `${change.attribute}=['"]([^"^']*)["']`,
        "gi"
      );
      // `${change.attribute}=['"]([^"^']*)["']`,
      svgField = svgField.replace(sourceRegexp, (match) => {
        return match.replace(destRegexp, newColorText);
      });
    });
    return svgField;
  }

  return null;
};

export default processSvg;
