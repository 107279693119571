import { Component } from "react";
import * as Sentry from "@sentry/browser";
import { connect } from "react-redux";

import { selectQueryProps } from "../modules/semantic/selectors";

class ErrorBoundary extends Component {
  componentDidCatch(error, errorInfo) {
    Sentry.addBreadcrumb({
      message: "ErrorBoundary",
      data: {
        did: this.props?.queryProps?.did,
        cid: this.props?.queryProps?.cid,
        errorInfo,
      },
    });
    Sentry.captureException(error);
  }

  render() {
    return this.props.children;
  }
}

export default connect((state) => ({
  queryProps: selectQueryProps(state),
}))(ErrorBoundary);
