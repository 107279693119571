export function resolvePath(object, path, pathSeparator, defaultValue) {
  return path
    .split(pathSeparator)
    .reduce((o, p) => (o ? o[p] : defaultValue), object);
}

export function setPath(object, path, pathSeparator, value) {
  return path.split(pathSeparator).reduce((o, p, i) => {
    o[p] = path.split(pathSeparator).length === ++i ? value : o[p] || {};
    return o[p];
  }, object);
}
