/* eslint-disable */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { trackAnalytics } from "../modules/config/actions";
import { setSemanticAsLoaded } from "../modules/semantic/actions";
import { selectSemantic } from "../modules/semantic/selectors";

const useComponentLoaded = ({
  loadedActionName = "window-load",
  width,
  height,
  elementId,
  semanticsHistoryMeta = {},
  additionalVerticalSpace = 0,
} = {}) => {
  const dispatch = useDispatch();

  const semantic = useSelector(selectSemantic);

  useEffect(() => {
    // callback function to call when event triggers
    const onPageLoad = () => {
      console.log("page loaded");
      // do something else
      const element = document.getElementById(elementId);
      let marginTop = 0;
      let marginBottom = 0;
      let marginLeft = 0;
      let marginRight = 0;
      const { width: elementWidth, height: elementHeight } =
        element?.getBoundingClientRect() || {
          width,
          height,
        };
      if (element) {
        const styles = window.getComputedStyle(element);
        marginTop = Number(styles.marginTop.replace("px", ""));
        marginBottom = Number(styles.marginBottom.replace("px", ""));
        marginLeft = Number(styles.marginLeft.replace("px", ""));
        marginRight = Number(styles.marginRight.replace("px", ""));
      }

      const size = {
        width: Math.ceil(elementWidth + marginLeft + marginRight),
        height:
          Math.ceil(elementHeight + marginTop + marginBottom) +
          additionalVerticalSpace,
      };

      window.chrome?.webview?.postMessage(
        JSON.stringify({
          ...size,
          event: "rendered",
          semanticId: semantic.id,
          flowId: semantic.flowId,
        })
      );
      window.parent?.postMessage(
        {
          ...size,
          type: "rendered",
          contentType: semantic?.trigger?.contentType,
          semanticId: semantic.id,
          flowId: semantic.flowId,
        },
        "*"
      );
      dispatch(trackAnalytics({ action: loadedActionName }));
      dispatch(setSemanticAsLoaded(semanticsHistoryMeta));
    };

    // Check if the page has already loaded
    if (document.readyState === "complete") {
      // document has fully loaded; scripts, images, stylesheets and frames..
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad, false);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);
};

export default useComponentLoaded;
