const primary = "rgb(36, 23, 81)"; // It was #E6444E red (until 2023/03/09)
const secondary = "rgb(148, 241, 178)"; // It was #33A6A5 green  (until 2023/03/09)

export const getColors = (overrides) => ({
  primary: (overrides && overrides.primary) || primary,
  secondary: (overrides && overrides.secondary) || secondary,
  white: "rgb(255, 255, 255)",
  grey1: "rgb(247, 247, 247)",
  grey2: "rgb(239, 239, 239)",
  grey3: "rgb(225, 225, 225)",
  grey4: "rgb(181, 181, 181)",
  grey5: "rgb(162,162,162)",
  grey6: "rgb(81, 81, 81)",
  grey7: "rgb(64, 64, 64)",
  dark: "rgb(25, 25, 25)",
  black: "rgb(0, 0, 0)",
});
