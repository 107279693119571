import { takeLatest } from "redux-saga/effects";
import { setError } from "./actions";

// eslint-disable-next-line require-yield
function* onSetError() {
  console.error("Error occurred");
  setTimeout(() => {
    window.parent?.postMessage(
      {
        type: "closed",
        iframeId: new URLSearchParams(location.search).get("iframeId"),
      },
      "*"
    );
    window.close();
  }, 100);
}

export default function* () {
  yield takeLatest(setError, onSetError);
}
