import { takeLatest, call, select, put } from "redux-saga/effects";

import {
  closeSemantic,
  getSemantic,
  ctaClick,
  primeMiniClose,
  primePostponeClick,
  resetClose,
  setClose,
  setSemantic,
  setSemanticAsLoaded,
  feedbackClick,
} from "./actions";
import {
  getImageBase64,
  getSemantic as getSemanticApi,
  patchUserState,
} from "./api";
import {
  selectDropdownValue,
  selectInitialProps,
  selectIsExtension,
  selectQueryProps,
  selectSemantic,
} from "./selectors";
import { selectConfig } from "../config/selectors";
import { trackAnalytics } from "../config/actions";
import { setError } from "../error/actions";
import * as Sentry from "@sentry/browser";
import { LEGACY_TEMPLATEFAMILY_ID } from "../../constConfig/config";

function* onGetSemantic({ payload }) {
  const props = yield select(selectInitialProps);

  if (!props) {
    yield put(setError());
  }

  if (props) {
    try {
      const semantic = yield call(getSemanticApi, {
        semanticId: payload,
        did: props.did,
        cid: props.cid,
      });
      if (!semantic) {
        throw new Error("Semantic missing");
      }

      console.log("semantic", semantic);

      const { tags } = semantic;
      const tagsArray = tags?.split(",").map((t) => t.trim()) ?? [];

      const { type } = semantic;
      console.log("type", type);

      let image = "";
      if (
        !semantic.templateFamilyId ||
        semantic?.templateFamilyId === LEGACY_TEMPLATEFAMILY_ID
      ) {
        // default template currently requires the image to be already fetched
        image = yield call(getImageBase64, semantic.imageId);
      } else {
        image = semantic.imageId;
      }

      yield put(setSemantic({ ...semantic, tagsArray, image }));
    } catch (e) {
      console.error("error in semantic saga:", e);
      Sentry.captureException(e);
      yield put(setError());
    }
  }
}

function* onPrimeMiniClose() {
  const config = yield select(selectConfig);

  setTimeout(() => {
    window.parent?.postMessage(
      { type: "closed", iframeId: config.iframeId },
      "*"
    );
    window.close();
  }, config.afterCTAClickCloseTimeout);
}

function* patchUserStateSaga({
  deviceId,
  path,
  value,
  type = "string",
  isFirstInFlow = false,
}) {
  const config = yield select(selectConfig);
  try {
    if (config?.scenario !== "5justctauserstatepath" && config.silent) {
      return;
    }

    // Commented, as usermeta tracking is desired also in case 1readagain
    // if (config?.scenario === "1readagain" && isFirstInFlow) {
    //   console.info("1readagain. Skipping patchUserState for firstInFlow");
    //   return;
    // }

    let patchPath = Array.isArray(path) ? path : [path];
    let patchType = (Array.isArray(type) ? type : [type]).filter(
      (_, i) => patchPath[i]
    );
    let patchValue = (Array.isArray(value) ? value : [value]).filter(
      (_, i) => patchPath[i]
    );

    patchPath = patchPath.filter(Boolean);

    patchPath.forEach((path, i) => {
      const typeUserStatePath = path.split("::");

      if (!patchType[i]) {
        patchType[i] = "string";
      }

      if (typeUserStatePath.length > 1) {
        patchType[i] = typeUserStatePath[1];
        patchPath[i] = typeUserStatePath[0];
      }
    });

    if (!patchPath.length) {
      return;
    }

    if (config?.scenario === "5justctauserstatepath") {
      // In this scenario, update just userStatePath
      const semantic = yield select(selectSemantic);
      patchPath.forEach((path, i) => {
        if (path === semantic.userStatePath) {
          patchPath = semantic.userStatePath;
          patchValue = Array.isArray(patchValue) ? patchValue[i] : patchValue;
          patchType = Array.isArray(patchType) ? patchType[i] : patchType;
        }
      });
    }

    yield call(patchUserState, {
      deviceId,
      path: patchPath,
      value: patchValue,
      type: patchType,
    });
  } catch (e) {
    console.error("Request failed", e);
    Sentry.captureException(e);
  }
}

function utilAttemptMarkSemanticMaterialCompleted(
  config,
  semantic,
  flowCompleteType,
  paths,
  types,
  values
) {
  // Note: materialId - flowId has a 1-1 relation
  if (!config.silent && semantic?.semanticLearningMaterial) {
    if (
      semantic.semanticLearningMaterial.flowCompleteType === flowCompleteType &&
      (
        semantic.semanticLearningMaterial.flowCompleteSemanticIds ?? []
      ).includes(semantic.id)
    ) {
      const now = Date.now();

      if (!paths.includes("completedMaterials")) {
        paths.push("completedMaterials");
        types.push("object");
        // At every completion, will overwrite lastCompletedAt
        values.push(
          JSON.stringify({
            [`${semantic.semanticLearningMaterial.id}`]: {
              flowId: semantic.semanticLearningMaterial.flowId,
              lastCompletedAt: now,
            },
          })
        );
      }
    }
  }
}

function* onSetSemanticAsLoaded({ payload }) {
  const props = yield select(selectInitialProps);
  const semantic = yield select(selectSemantic);
  const config = yield select(selectConfig);

  if (!props || !semantic) {
    yield put(setError());
  }

  const paths = [];
  const types = [];
  const values = [];

  const { outcome, additionalLogic } = semantic.survey || {};
  const now = Date.now();

  utilAttemptMarkSemanticMaterialCompleted(
    config,
    semantic,
    "tip-reached",
    paths,
    types,
    values
  );

  if (
    semantic?.survey?.type === "hi" &&
    ["no-outcome", "self-evaluation"].includes(outcome?.type) &&
    outcome?.hiCompleteType === "tip-reached" &&
    (outcome.hiCompleteSemanticIds ?? []).includes(semantic.id)
  ) {
    paths.push("surveys");
    types.push("object");
    values.push(
      JSON.stringify({
        [`${semantic.survey.id}-${now}`]: {
          completedAt: new Date(now).toISOString(),
        },
      })
    );

    paths.push("interactionOn");
    types.push("object");
    values.push(
      JSON.stringify({
        semanticId: semantic.semanticId,
        interactionOn: now,
        scenario: config.scenario,
      })
    );

    if (!semantic.tagsArray.includes("DONT_ADVANCE_JOURNEY")) {
      paths.push("lastInteractionOn");
      types.push("object");
      values.push(
        JSON.stringify({
          journeyId: semantic.journeyId,
          timestamp: now,
        })
      );
    }
  }

  if (
    semantic?.survey?.type === "hi" &&
    outcome?.type === "category-single" &&
    outcome?.hiAssignType === "tip-reached" &&
    outcome?.hiSemanticCategoryMapping?.some(
      ({ semanticId }) => semantic.id === semanticId
    ) &&
    outcome.hiUserStatePath
  ) {
    const { category } = outcome?.hiSemanticCategoryMapping?.find(
      ({ semanticId }) => semantic.id === semanticId
    );

    let status;

    if (additionalLogic?.successFail?.isEnabled) {
      const option = additionalLogic.successFail.values.find(
        (valueOption) => valueOption.value === category
      );

      if (option) {
        status = option.isSuccess ? "succeeded" : "failed";
      }
    }

    paths.push("surveys");
    types.push("object");
    values.push(
      JSON.stringify({
        [`${semantic.survey.id}-${now}`]: {
          completedAt: new Date(now).toISOString(),
          outcome: {
            [outcome.hiUserStatePath]: category,
          },
          ...(status && { status }),
        },
      })
    );

    paths.push(outcome.hiUserStatePath);
    types.push("string");
    values.push(category);

    paths.push("interactionOn");
    types.push("object");
    values.push(
      JSON.stringify({
        semanticId: semantic.semanticId,
        interactionOn: now,
        scenario: config.scenario,
      })
    );

    if (!semantic.tagsArray.includes("DONT_ADVANCE_JOURNEY")) {
      paths.push("lastInteractionOn");
      types.push("object");
      values.push(
        JSON.stringify({
          journeyId: semantic.journeyId,
          timestamp: now,
        })
      );
    }
  }

  if (config.silent) {
    console.info("Silent mode. Skipping trackAnalytics");
    return;
  }

  try {
    const survey = [];

    if (semantic.survey) {
      survey.push(semantic.survey.id);
    }

    if (semantic.cta1Survey) {
      survey.push(semantic.cta1Survey.id);
    }

    if (semantic.cta2Survey) {
      survey.push(semantic.cta2Survey.id);
    }

    const dropdownDataSurveys = (semantic.dropdownData ?? []).filter(
      ({ ctaSurvey }) => ctaSurvey
    );
    if (dropdownDataSurveys.length) {
      survey.push(...dropdownDataSurveys.map(({ ctaSurvey }) => ctaSurvey.id));
    }

    // update also timeZone
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    if (timeZone) {
      values.push(timeZone);
      paths.push("timeZone");
      types.push("string");
    }

    yield call(patchUserStateSaga, {
      deviceId: props.did,
      path: ["semanticsHistory", ...paths],
      value: [
        JSON.stringify({
          ...payload,
          ...(survey.length && {
            survey,
          }),
          semanticId: semantic.semanticId,
          stimulusId: semantic.stimulusId,
          stimulusType: semantic.type,
          currentTime: String(Date.now()),
          isFirstInFlow: semantic.isFirstInFlow,
          tags: semantic.tags,
          scenario: config.scenario,
        }),
        ...values,
      ],
      type: ["object", ...types],
      isFirstInFlow: semantic.isFirstInFlow,
    });
  } catch (e) {
    console.error("Request failed", e);
    Sentry.captureException(e);
  }
}

function* finalizeFlow() {
  const semantic = yield select(selectSemantic);
  const successBlock = document.querySelector("#success-container");
  const config = yield select(selectConfig);

  if (
    semantic.feedbackPrimeQuestion &&
    successBlock &&
    successBlock.style.display !== "flex"
  ) {
    // if there is a feedback prime for this stimulus,
    // it should be rendered, instead of closing the stimulus directly
    successBlock.style.display = "flex";
    yield put(resetClose());

    const action = "feedback-shown";
    yield put(
      trackAnalytics({
        action,
        additionalFields: {
          campaignId: semantic.feedbackPrimeAnalyticsId,
        },
      })
    );
    return;
  }

  setTimeout(() => {
    window.parent?.postMessage(
      { type: "closed", iframeId: config.iframeId },
      "*"
    );
    window.close();
  }, config.afterCTAClickCloseTimeout);
}

function* onSemanticClose() {
  const config = yield select(selectConfig);
  const props = yield select(selectInitialProps);
  const semantic = yield select(selectSemantic);

  if (config.exitClicked) {
    setTimeout(() => {
      window.parent?.postMessage(
        { type: "closed", iframeId: config.iframeId },
        "*"
      );
      window.close();
    }, config.afterCTAClickCloseTimeout);
    return;
  }

  yield put(setClose());
  let action = "exit-click";

  if (
    semantic.type === "PRIME" &&
    !semantic.tagsArray.includes("DONT_ADVANCE_JOURNEY") &&
    !semantic.tagsArray.includes("DONT_ADVANCE_JOURNEY_FROM_SURVEY") &&
    config.scenario !== "fromLearningMaterial"
  ) {
    try {
      const now = Date.now();
      yield call(patchUserStateSaga, {
        deviceId: props.did,
        path: ["lastInteractionOn", "interactionOn"],
        value: [
          JSON.stringify({
            journeyId: semantic.journeyId,
            timestamp: now,
          }),
          JSON.stringify({
            semanticId: semantic.semanticId,
            interactionOn: now,
            scenario: config.scenario,
          }),
        ],
        type: ["object", "object"],
        isFirstInFlow: semantic.isFirstInFlow,
      });
    } catch (e) {
      console.error("Request failed", e);
      Sentry.captureException(e);
    }

    yield put(
      trackAnalytics({
        action: "LAST_INTERACTION_ON_UPDATED",
        value: String(Date.now()),
        source: "stimulus-exit",
      })
    );
  } else if (
    !semantic.tagsArray.includes("DONT_ADVANCE_JOURNEY_FROM_SURVEY") &&
    config.scenario !== "fromLearningMaterial"
  ) {
    yield call(patchUserStateSaga, {
      deviceId: props.did,
      path: "interactionOn",
      value: JSON.stringify({
        semanticId: semantic.semanticId,
        interactionOn: Date.now(),
        scenario: config.scenario,
      }),

      type: "object",
      isFirstInFlow: semantic.isFirstInFlow,
    });
  }

  const successBlock = document.querySelector("#success-container");
  if (
    semantic.feedbackPrimeQuestion &&
    successBlock &&
    successBlock.style.display === "flex"
  ) {
    // if there is a feedback prime for this stimulus and it is shown,
    // we should set the action to feedback-closed
    action = "feedback-closed";
  }

  yield put(
    trackAnalytics({
      action,
    })
  );

  yield call(finalizeFlow);
}

function* openLink({
  ctaTarget,
  shouldOpenInNewWindow,
  shouldAddAuthParams,
  skipPreviewAndSilentParams = false, // preview and silent params could break external links, for example
  surveyFromCTA = false, //used to identify wether the surveys is open from a cta or a deep link directly
  previousSemanticId = null, //semanticid of the message leading to the opening of the link
  from = null, //whether the link is opened via tip/nudge/card
}) {
  const { did, cid, eid } = yield select(selectQueryProps);

  if (!ctaTarget) {
    return;
  }

  let url;
  try {
    url = new URL(ctaTarget.replace(":did", did));
  } catch (err) {
    console.error("Link is not valid", err);
    Sentry.captureException(err);
    return;
  }

  if (shouldAddAuthParams) {
    url.searchParams.append("did", did);
    url.searchParams.append("cid", cid);
    url.searchParams.append("eid", eid);
  }

  const config = yield select(selectConfig);

  if (!skipPreviewAndSilentParams && config.preview) {
    url.searchParams.append("preview", "true");
  }

  if (!skipPreviewAndSilentParams && config.silent) {
    url.searchParams.append("silent", "true");
  }

  if (surveyFromCTA) {
    url.searchParams.append("surveyFromCTA", "true");
  }

  if (from === "Message") {
    url.searchParams.append("from", "Message");
    previousSemanticId &&
      url.searchParams.append("previousSemanticId", previousSemanticId);
  }
  const isHIWeb = yield select(selectIsExtension);
  if (isHIWeb && shouldOpenInNewWindow) {
    window.parent?.postMessage({ type: "openURL", url: url.toString() }, "*");
    // For Diary, Interactions in Settings in User Dashboard.
    window.parent?.parent?.postMessage(
      { type: "openURL", url: url.toString() },
      "*"
    );
    return;
  }

  if (shouldOpenInNewWindow) {
    window.open(url.toString());
    return;
  }

  window.location.replace(url);
}

function* openSemantic({ ctaTarget, shouldOpenInNewWindow }) {
  const config = yield select(selectConfig);
  const initialProps = yield select(selectQueryProps);
  const domainName = process.env.REACT_APP_account_domain_name || "";
  const endpoint = `https://render.${domainName}/`;
  // to debug a flow, locally:
  // const endpoint = `http://localhost:3000/`;

  const props = { ...initialProps, semanticId: ctaTarget };
  const stringifiedProps = JSON.stringify(props);
  const encodedBase64Props = window.btoa(stringifiedProps);

  const url = new URL(endpoint);
  url.searchParams.append("props", encodedBase64Props);

  if (config.silent) {
    url.searchParams.append("silent", "true");
  }

  if (config.source) {
    url.searchParams.append("source", config.source);
  }

  if (config.scenario) {
    // && config.scenario === "fromLearningMaterial"
    // Extending now to all scenarios
    url.searchParams.append("scenario", config.scenario);
  }

  yield call(openLink, {
    ctaTarget: url.toString(),
    shouldOpenInNewWindow,
  });
}

function* performAction({
  ctaType,
  ctaTarget,
  shouldOpenInNewWindow,
  shouldAddAuthParams,
  previousSemanticId,
  from,
}) {
  console.log({
    ctaType,
    ctaTarget,
    shouldOpenInNewWindow,
    shouldAddAuthParams,
  });
  const config = yield select(selectConfig);

  if (!ctaTarget) {
    console.log("no cta target, closing");
    yield call(finalizeFlow);
    return;
  }

  const skipPreviewAndSilentParams = ctaType === "External Link";

  switch (ctaType) {
    case "Semantic":
      yield call(openSemantic, {
        ctaTarget,
        shouldOpenInNewWindow,
        previousSemanticId,
        from,
      });
      break;

    case "Special Link":
    case "Dashboard Step":
    case "Survey":
      yield call(openLink, {
        ctaTarget,
        shouldOpenInNewWindow,
        shouldAddAuthParams,
        surveyFromCTA: true,
        previousSemanticId,
        from,
      });
      yield call(finalizeFlow);
      break;

    case "External Link":
      yield call(openLink, {
        ctaTarget,
        shouldOpenInNewWindow,
        shouldAddAuthParams,
        skipPreviewAndSilentParams,
        previousSemanticId,
        from,
      });
      yield call(finalizeFlow);
      break;

    case "Dashboard Material":
    case "Prompt":
    case "Prompt Tool":
      yield call(openLink, {
        ctaTarget,
        shouldOpenInNewWindow,
        shouldAddAuthParams,
        previousSemanticId,
        from,
      });
      setTimeout(() => {
        window.parent?.postMessage(
          { type: "closed", iframeId: config.iframeId },
          "*"
        );
        window.close();
      }, config.afterCTAClickCloseTimeout);
      break;
    
    default:
      console.log("no supported cta type, closing");
      yield call(finalizeFlow);
  }
}

function* onCTAClick({
  payload: { ctaType, ctaTarget, shouldOpenInNewWindow, shouldAddAuthParams },
}) {
  const semantic = yield select(selectSemantic);
  const props = yield select(selectInitialProps);
  const dropdownValue = yield select(selectDropdownValue);
  const config = yield select(selectConfig);

  const paths = [];
  const types = [];
  const values = [];
  const now = Date.now();

  const { outcome, additionalLogic } = semantic.survey || {};

  utilAttemptMarkSemanticMaterialCompleted(
    config,
    semantic,
    "cta-clicked",
    paths,
    types,
    values
  );

  if (
    !config.silent &&
    semantic?.survey?.type === "hi" &&
    ["no-outcome", "self-evaluation"].includes(outcome?.type) &&
    outcome?.hiCompleteType === "cta-clicked" &&
    (outcome.hiCompleteSemanticIds ?? []).includes(semantic.id)
  ) {
    paths.push("surveys");
    types.push("object");
    values.push(
      JSON.stringify({
        [`${semantic.survey.id}-${now}`]: {
          completedAt: new Date(now).toISOString(),
        },
      })
    );

    paths.push("interactionOn");
    types.push("object");
    values.push(
      JSON.stringify({
        semanticId: semantic.semanticId,
        interactionOn: now,
        scenario: config.scenario,
      })
    );

    if (!semantic.tagsArray.includes("DONT_ADVANCE_JOURNEY")) {
      paths.push("lastInteractionOn");
      types.push("object");
      values.push(
        JSON.stringify({
          journeyId: semantic.journeyId,
          timestamp: now,
        })
      );
    }
  }

  if (
    !config.silent &&
    semantic?.survey?.type === "hi" &&
    outcome?.type === "category-single" &&
    outcome?.hiAssignType === "dropdown-selection" &&
    semantic.id === outcome?.hiDropdownSemanticId
  ) {
    let status;

    if (additionalLogic?.successFail?.isEnabled) {
      const option = additionalLogic.successFail.values.find(
        (valueOption) => valueOption.value === dropdownValue?.value
      );

      if (option) {
        status = option.isSuccess ? "succeeded" : "failed";
      }
    }

    paths.push("surveys");
    types.push("object");
    values.push(
      JSON.stringify({
        [`${semantic.survey.id}-${now}`]: {
          completedAt: new Date(now).toISOString(),
          outcome: {
            [semantic.userStatePath]: dropdownValue?.value,
          },
          ...(status && { status }),
        },
      })
    );

    paths.push("interactionOn");
    types.push("object");
    values.push(
      JSON.stringify({
        semanticId: semantic.semanticId,
        interactionOn: now,
        scenario: config.scenario,
      })
    );

    if (!semantic.tagsArray.includes("DONT_ADVANCE_JOURNEY")) {
      paths.push("lastInteractionOn");
      types.push("object");
      values.push(
        JSON.stringify({
          journeyId: semantic.journeyId,
          timestamp: now,
        })
      );
    }
  }

  try {
    if (
      semantic.type === "PRIME" &&
      !semantic.tagsArray.includes("DONT_ADVANCE_JOURNEY") &&
      !semantic.tagsArray.includes("DONT_ADVANCE_JOURNEY_FROM_SURVEY") &&
      config.scenario !== "fromLearningMaterial"
    ) {
      yield call(patchUserStateSaga, {
        deviceId: props.did,
        path: [
          "lastInteractionOn",
          "interactionOn",
          semantic.userStatePath,
          ...paths,
        ],
        type: ["object", "object", "string", ...types],
        value: [
          JSON.stringify({
            journeyId: semantic.journeyId,
            timestamp: now,
          }),
          JSON.stringify({
            semanticId: semantic.semanticId,
            interactionOn: now,
            scenario: config.scenario,
          }),
          dropdownValue?.value,
          ...values,
        ],
        isFirstInFlow: semantic.isFirstInFlow,
      });
      yield put(
        trackAnalytics({
          action: "LAST_INTERACTION_ON_UPDATED",
          value: String(Date.now()),
          source: "stimulus-exit",
        })
      );
    } else {
      yield call(patchUserStateSaga, {
        deviceId: props.did,
        path: [
          ...(semantic.tagsArray.includes("DONT_ADVANCE_JOURNEY_FROM_SURVEY") ||
          config.scenario === "fromLearningMaterial"
            ? []
            : ["interactionOn"]),
          semantic.userStatePath,
          ...paths,
        ],
        type: [
          ...(semantic.tagsArray.includes("DONT_ADVANCE_JOURNEY_FROM_SURVEY") ||
          config.scenario === "fromLearningMaterial"
            ? []
            : ["object"]),
          "string",
          ...types,
        ],
        value: [
          ...(semantic.tagsArray.includes("DONT_ADVANCE_JOURNEY_FROM_SURVEY") ||
          config.scenario === "fromLearningMaterial"
            ? []
            : [
                JSON.stringify({
                  semanticId: semantic.semanticId,
                  interactionOn: Date.now(),
                  scenario: config.scenario,
                }),
              ]),
          dropdownValue?.value,
          ...values,
        ],
        isFirstInFlow: semantic.isFirstInFlow,
      });
    }
  } catch (e) {
    console.error("Request failed", e);
    Sentry.captureException(e);
  }

  yield call(performAction, {
    ctaType,
    ctaTarget,
    shouldOpenInNewWindow,
    shouldAddAuthParams,
    previousSemanticId: semantic.semanticId,
    from: "Message",
  });
}

function* onFeedbackClick({ payload: id }) {
  const { feedbackPrimeAnalyticsId } = yield select(selectSemantic);
  const config = yield select(selectConfig);

  yield put(
    trackAnalytics({
      action: "success-cta-click",
      additionalFields: {
        which: id,
        campaignId: feedbackPrimeAnalyticsId,
      },
    })
  );

  setTimeout(() => {
    window.parent?.postMessage(
      { type: "closed", iframeId: config.iframeId },
      "*"
    );
    window.close();
  }, config.afterCTAClickCloseTimeout);
}

export default function* () {
  yield takeLatest(getSemantic, onGetSemantic);
  yield takeLatest(primePostponeClick, onPrimeMiniClose);
  yield takeLatest(closeSemantic, onSemanticClose);
  yield takeLatest(primeMiniClose, onPrimeMiniClose);
  yield takeLatest(setSemanticAsLoaded, onSetSemanticAsLoaded);
  yield takeLatest(ctaClick, onCTAClick);
  yield takeLatest(feedbackClick, onFeedbackClick);
}
