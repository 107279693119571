const userStateEndpoint = process.env.REACT_APP_httpendpoints_user_state || "";
const domainName = process.env.REACT_APP_account_domain_name || "";

export const getSemantic = async ({ semanticId, did, cid }) => {
  if (!domainName) {
    throw new Error("Endpoint is nto defined");
  }

  const qs = new URLSearchParams([
    ["semanticId", semanticId],
    ["cid", cid],
    ["did", did],
  ]).toString();

  const endpoint = `https://rtf.${domainName}/semantic`;

  const response = await fetch(`${endpoint}?${qs}`, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "GET",
  });

  if (response.status !== 200) {
    throw new Error("The request returned non 200 response");
  }

  const semanticResponse = await response.json();

  return semanticResponse.semantic;
};

export const getImageBase64 = async (image) => {
  if (!image) {
    return "";
  }

  try {
    const response = await fetch(image);
    const result = await response.arrayBuffer();

    window.Buffer = window.Buffer || require("buffer").Buffer;
    return `data:image/png;base64,${Buffer.from(result).toString("base64")}`;
  } catch (e) {
    console.error("Error reading image: ", e);
  }

  return image;
};

export const patchUserState = async ({
  deviceId,
  path,
  value,
  type = "string",
}) => {
  if (!userStateEndpoint) {
    const message = "User state endpoint is not specified";
    console.warn(message);
    return Promise.resolve({ status: 400, message });
  }

  const patchPath = Array.isArray(path) ? path : [path];
  const patchType = Array.isArray(type) ? type : [type];
  const patchValue = Array.isArray(value) ? value : [value];

  const qs = new URLSearchParams();

  patchPath.forEach((path, i) => {
    qs.append("path", path);
    qs.append("type", patchType[i]);
    qs.append("value", patchValue[i]);
  });

  return fetch(`${userStateEndpoint.replace("{did}", deviceId)}?${qs}`, {
    method: "PATCH",
  });
};
