import { all, fork } from "redux-saga/effects";
import semantic from "../modules/semantic/sagas";
import config from "../modules/config/sagas";
import error from "../modules/error/sagas";

export default function* rootSaga(services, context) {
  yield all(
    [semantic, config, error].map((saga) => fork(saga, services, context))
  );
}
