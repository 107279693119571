import loadFonts from "./loadFonts";
import { resolvePath, setPath } from "./manipulatePath";
import processRgb from "./processRgb";
import processSvg from "./processSvg";

const templatePreProcessStyles = function (
  template,
  templateColors,
  templateFonts,
  previewOrSemantic,
  image = null,
  backgrounds = null
) {
  const styleObjectName = `${previewOrSemantic}Style`;
  // to test:
  // templateFonts = {
  //   titlefontfamily: '"Segoe UI", Helvetica, Arial, sans-serif',
  //   textfontfamily: "Open Sans, sans-serif",
  //   titlefontsize: "30px",
  //   textfontsize: "10px",
  //   ctafontfamily: '"Segoe UI", Helvetica, Arial, sans-serif',
  // };
  // templateFonts.ctafontfamily = '"Segoe UI", Helvetica, Arial, sans-serif';

  // ==== START update template[styleObjectName]["@font-face"] to reflect customization
  // fontfamily customizations are all optional; if there are, update "@font-face" object/array, to include the new font/s
  const fontfamilycustomizations = Object.keys(templateFonts || {}).filter(
    // ends with fontfamily and is not null or undefined or "" and is among customizable template elements
    (el) =>
      el.endsWith("fontfamily") &&
      templateFonts[el] &&
      template?.userCustomizable?.vars?.templateFonts?.[el]
  );
  fontfamilycustomizations.forEach((el) => {
    // example of value is: withFallback: "Open Sans, sans-serif"
    // take what comes before the first comma, or (there is no comma) the full string
    // also remove quotes ""; in some cases, there are quotes around the font name
    const fontName = (
      templateFonts[el].substring(0, templateFonts[el].indexOf(",")) ||
      templateFonts[el]
    ).replace(/^"|"$/g, "");

    if (fontName === "Segoe UI") {
      // this one is retrieved from local, currently (assuming it is always available in local machine)
      return;
    }

    const newFontFaceObj = {
      fontFamily: fontName,
      fontStyle: "normal",
      // font name with _ instead of spaces; then font name without spaces
      src: `url(fonts/${fontName.replace(" ", "_")}/static/${fontName.replace(
        /\s+/g,
        ""
      )}-Regular.ttf) format("truetype")`,
    };

    if (!template?.[styleObjectName]?.["@font-face"]) {
      // if there is not, add it
      template[styleObjectName]["@font-face"] = newFontFaceObj;
    } else if (Array.isArray(template?.[styleObjectName]["@font-face"])) {
      // if it is an array, push to it
      // add it just in case it is not already present
      if (
        !template[styleObjectName]["@font-face"].filter(
          (obj) => obj.fontFamily === fontName
        ).length > 0
      ) {
        template[styleObjectName]["@font-face"].push(newFontFaceObj);
      }
    }
  });
  // ==== END update template[styleObjectName]["@font-face"]
  // TODO! Currently there is the possibility to have unused import statements (the template default ones.. )
  // .. assure fontFamilies are all declared in template config, so you can adjust..

  // Currently this FE version supports only one element in @font-face;
  // if array is provided, none of the fonts will be loaded.
  // In case of fonts array; iterate and load them with js
  // Alternative: could produce css directly at back-end, with https://github.com/cssinjs/jss/issues/199
  if (
    template?.[styleObjectName]["@font-face"] &&
    Array.isArray(template?.[styleObjectName]["@font-face"])
  ) {
    loadFonts(template?.[styleObjectName]["@font-face"]);
  }

  // Apply colors (main css variables)
  if (template?.userCustomizable?.vars?.rootCssVars) {
    Object.keys(template.userCustomizable.vars.rootCssVars).forEach((el) => {
      template[styleObjectName][template.rootCssSelector][
        template.userCustomizable.vars.rootCssVars[el].cssProperty
      ] = templateColors?.[el]
        ? templateColors[el]
        : template.userCustomizable.vars.rootCssVars[el].default;
    });
  }

  // Apply fonts (main css variables); size and fontFamily
  if (template?.userCustomizable?.vars?.templateFonts) {
    Object.keys(template.userCustomizable.vars.templateFonts).forEach((el) => {
      template[styleObjectName][template.rootCssSelector][
        template.userCustomizable.vars.templateFonts[el].cssProperty
      ] = templateFonts?.[el]
        ? templateFonts[el]
        : template.userCustomizable.vars.templateFonts[el].default;
    });
  }

  // Apply colors to svg elements
  if (template?.userCustomizable?.vars?.rootCssVars) {
    Object.keys(template.userCustomizable.vars.rootCssVars)
      .filter((el) => el.startsWith("color"))
      .forEach((el) => {
        if (template.userCustomizable.vars.rootCssVars[el]?.applyToSvg) {
          // apply color template.userCustomizable.vars.rootCssVars[el].default to svg specified in array: applyToSvg
          template.userCustomizable.vars.rootCssVars[el].applyToSvg.forEach(
            (svgInfo) => {
              // pass the svg data, the desired color, the specification of which elements need to change
              const resultingSvg = processSvg(
                // pass (object, path, pathSeparator, defaultValue)
                resolvePath(
                  template[styleObjectName],
                  svgInfo.path,
                  template.stringPathSeparator,
                  null
                ),
                templateColors?.[el]
                  ? templateColors[el]
                  : template.userCustomizable.vars.rootCssVars[el].default,
                svgInfo.changes
              );
              // pass (object, path, pathSeparator, value)
              setPath(
                template[styleObjectName],
                svgInfo.path,
                template.stringPathSeparator,
                resultingSvg
              );
            }
          );
        }
      });
  }

  // Apply colors to rgb elements
  if (template?.userCustomizable?.vars?.rootCssVars) {
    Object.keys(template.userCustomizable.vars.rootCssVars)
      .filter((el) => el.startsWith("color"))
      .forEach((el) => {
        if (template.userCustomizable.vars.rootCssVars[el]?.applyToRgb) {
          // apply color template.userCustomizable.vars.rootCssVars[el].default to svg specified in array: applyToSvg
          template.userCustomizable.vars.rootCssVars[el].applyToRgb.forEach(
            (rgbInfo) => {
              // pass the rgb data, the desired color, in case specification of how to handle change
              const resultingRgb = processRgb(
                // pass (object, path, pathSeparator, defaultValue)
                resolvePath(
                  template[styleObjectName],
                  rgbInfo.path,
                  template.stringPathSeparator,
                  null
                ),
                templateColors?.[el]
                  ? templateColors[el]
                  : template.userCustomizable.vars.rootCssVars[el].default,
                rgbInfo.changes
              );
              // pass (object, path, pathSeparator, value)
              setPath(
                template[styleObjectName],
                rgbInfo.path,
                template.stringPathSeparator,
                resultingRgb
              );
            }
          );
        }
      });
  }

  // TODO when you will implement support for also the other kind of images.. bg1, bg2 etc., use templateImages received as param
  const templateImages = {};

  // apply images
  if (template?.userCustomizable?.images) {
    Object.keys(template.userCustomizable.images).forEach((el) => {
      if (
        template.userCustomizable.images[el]?.allowed &&
        template.userCustomizable.images[el]?.applyImage
      ) {
        // TODO.. if you refactor to move image to templateImages, remove this
        if (el === "image") {
          // this is to uniform image to the other images..
          templateImages.image = image;
        } else if (el === "bg1") {
          templateImages.bg1 = backgrounds?.bg1;
        } else {
          templateImages.bg2 = backgrounds?.bg2;
        }

        // apply color template.userCustomizable.vars.rootCssVars[el].default to svg specified in array: applyToSvg
        template.userCustomizable.images[el].applyImage.forEach(
          (imgApplyInfo) => {
            const imageToApply =
              !templateImages[el] && imgApplyInfo.default
                ? imgApplyInfo.default
                : templateImages[el];
            if (imageToApply === null) {
              // TODO error management; send notification
              console.error("missing image " + el);
            }

            // to process and apply image to different places; backgroundImage, svg, apply trimming etc.
            // pass (inputValue, changes)
            if (templateImages[el]) {
              imgApplyInfo.changes.forEach((change) => {
                if (!change.type || change.type === "simplyapply") {
                  // pass (object, path, pathSeparator, value)
                  setPath(
                    template[styleObjectName],
                    imgApplyInfo.path,
                    template.stringPathSeparator,
                    `url(${imageToApply})`
                  );
                }
              });
            }
          }
        );
      }
    });
  }
};

export default templatePreProcessStyles;
