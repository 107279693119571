import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectSemanticType,
  selectSemantic,
  selectQueryProps,
  selectInitialProps,
} from "../modules/semantic/selectors";
import PrimeMini from "./PrimeMini";
import Semantic from "./Semantic";
import { trackAnalytics } from "../modules/config/actions";
import { selectConfig } from "../modules/config/selectors";
import {
  LEGACY_TEMPLATEFAMILY_ID,
  LEGACY_SEMANTICTEMPLATE_ID,
  NO_PREVIEW_ID,
  PREVIEW_LEGACY,
} from "../constConfig/config.js";

const supportsMiniTip = ({
  type,
  templateFamilyId,
  semanticTemplateId,
  semanticPreviewTemplateId,
  isFirstInFlow,
  leafVersion,
  scenario = null,
}) => {
  if (!isFirstInFlow) {
    // semanticPreview can only be shown for the first
    // semantic in a flow
    return false;
  }

  if (scenario && scenario === "1readagain") {
    // in user-dashboard daily tip banner, scenario 1readagain, avoid showing minitip
    return false;
  }

  if (
    type === "NUDGE" &&
    (!templateFamilyId || templateFamilyId === LEGACY_TEMPLATEFAMILY_ID)
  ) {
    // Nudges have a different default: by default and for legacy, no preview, unless explicity set
    return false;
  }

  // Take in consideration semanticPreviewTemplateId just in case of proper selection of the previous fields
  if (
    templateFamilyId &&
    semanticTemplateId &&
    semanticPreviewTemplateId === NO_PREVIEW_ID
  ) {
    return false;
  }

  if (!leafVersion) {
    return false;
  }

  if (
    leafVersion?.raw?.startsWith("hi-web-") ||
    leafVersion?.raw?.startsWith("hi-extension-")
  ) {
    return true;
  }

  if (leafVersion?.raw === "23.99.0") {
    // teams-app; treat it like latest leaf version
    return true;
  }

  if (leafVersion?.raw === "1.2.3.4") {
    // development versions
    return true;
  }

  const { major, minor, patch } = leafVersion;
  if (major < 24) {
    return false;
  }

  return !(major === 24 && minor === 0 && patch < 75);
};

const VALID_TYPES = ["NUDGE", "PRIME"];

export const RendererComponent = () => {
  const dispatch = useDispatch();
  const type = useSelector(selectSemanticType);
  const queryProps = useSelector(selectQueryProps);
  const { iframeId, scenario } = useSelector(selectConfig);
  const semantic = useSelector(selectSemantic);
  const initialProps = useSelector(selectInitialProps);

  useEffect(() => {
    let timer;
    if (queryProps && queryProps.timeout) {
      timer = setTimeout(() => {
        window.parent?.postMessage({ type: "closed", iframeId }, "*");
        window.close();
      }, queryProps.timeout * 1000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [queryProps]);

  useEffect(
    () => {
      const timer = setTimeout(() => {
        if (!VALID_TYPES.includes(type)) {
          dispatch(trackAnalytics({ action: "renderer-timeout" }));
          setTimeout(() => {
            window.parent?.postMessage({ type: "closed", iframeId }, "*");
            window.close();
          }, 500);
        }
      }, 60 * 1000);

      return () => {
        clearTimeout(timer);
      };
    },
    // close window if no type rendered
    [type, dispatch]
  );

  useEffect(() => {
    // do not play audio in any case, if you are in a tipOfTheDay scenario (user-dashboard)
    // or if there is scenario because of fromLearningMaterial
    // (user clicked and expects something to appear; no need to attract user attention)
    if (
      scenario ||
      !type ||
      !VALID_TYPES.includes(type) ||
      !queryProps?.isSoundEnabled ||
      !semantic?.isFirstInFlow
    ) {
      return;
    }

    const audio = new Audio("sound.wav");
    const playPromise = audio.play();

    if (playPromise !== undefined) {
      playPromise
        .then((_) => {
          // Automatic playback started!
          // In case needed, show playing UI.
        })
        .catch((error) => {
          // Auto-play was prevented
          // In case needed, show paused UI.
          console.log("Auto-play was prevented: ", error.message);
          // Note: intentionally not sending this error to log/analytics
        });
    }
  }, [type, queryProps, semantic?.isFirstInFlow, scenario]);

  if (!semantic) {
    return null;
  }

  if (type === "PRIME" || type === "NUDGE") {
    const showMiniTip = supportsMiniTip({
      type,
      templateFamilyId:
        semantic?.templateFamilyId &&
        semantic?.semanticTemplateId &&
        semantic?.semanticPreview?.semanticPreviewTemplateId
          ? semantic.templateFamilyId
          : LEGACY_TEMPLATEFAMILY_ID,
      semanticTemplateId:
        semantic?.templateFamilyId &&
        semantic?.semanticTemplateId &&
        semantic?.semanticPreview?.semanticPreviewTemplateId
          ? semantic.semanticTemplateId
          : LEGACY_SEMANTICTEMPLATE_ID,
      semanticPreviewTemplateId:
        semantic?.templateFamilyId &&
        semantic?.semanticTemplateId &&
        semantic?.semanticPreview?.semanticPreviewTemplateId
          ? semantic.semanticPreview.semanticPreviewTemplateId
          : PREVIEW_LEGACY,
      isFirstInFlow: semantic?.isFirstInFlow,
      leafVersion: initialProps?.leafVersion,
      scenario,
    });

    if (showMiniTip) {
      return (
        <PrimeMini
          type={type}
          isAnimationEnabled={queryProps.isAnimationEnabled}
        >
          <Semantic type={type} animation={false} />
        </PrimeMini>
      );
    }

    return (
      <Semantic
        type={type}
        animation={
          Boolean(semantic.isFirstInFlow) &&
          Boolean(queryProps.isAnimationEnabled)
        }
      />
    );
  }

  return <h1>No component found</h1>;

  // For reference: situation before end of 202209
  // switch (type) {
  //   case "PRIME": {
  //     const showMiniTip = supportsMiniTip({
  //       isFirstInFlow: semantic?.isFirstInFlow,
  //       leafVersion: initialProps?.leafVersion,
  //     });

  //     if (showMiniTip) {
  //       return (
  //         <PrimeMini isAnimationEnabled={queryProps.isAnimationEnabled}>
  //           <Semantic type={type} animation={false} />
  //         </PrimeMini>
  //       );
  //     }

  //     return (
  //       <Semantic
  //         type={type}
  //         animation={
  //           Boolean(semantic.isFirstInFlow) &&
  //           Boolean(queryProps.isAnimationEnabled)
  //         }
  //       />
  //     );
  //   }

  //   case "NUDGE": {
  //     return <Semantic type={type} animation={queryProps.isAnimationEnabled} />;
  //   }

  //   default:
  //     return <h1>No component found</h1>;
  // }
};
