import { hexToRgb } from "./colorConversions";

const processRgb = (rgbField, desiredColor, changes) => {
  // alter Rgb field;

  // currently only handling the case of fields with direct rgb( (not other values) and transparency
  if (rgbField && rgbField.startsWith("rgb(")) {
    changes.forEach((change) => {
      if (change.element === "fieldValueWithTransparency") {
        const newColorRgb =
          desiredColor !== null ? hexToRgb(desiredColor) : null;
        if (newColorRgb) {
          const transparencySpec = rgbField.substring(
            rgbField.lastIndexOf(",") + 1
          );
          rgbField = `rgb(${newColorRgb.r},${newColorRgb.g},${newColorRgb.b},${transparencySpec}`;
        }
      }
    });
    return rgbField;
  }

  return null;
};

export default processRgb;
