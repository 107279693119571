// @flow strict
const { stripHtml } = require("string-strip-html");
/* prettier-ignore */
const sanitizeObject = <T: { +[key: string]: mixed, ... }> (obj: T): $Shape < T > => {
  const result = {};

  for (const key in obj) {
    if ({}.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      if (typeof value === "string") {
        result[key] = stripHtml(value).result;
      } else if (typeof value === "object") {
        if (value) {
          result[key] = sanitizeObject(value);
        }
      } else {
        result[key] = value;
      }
    }
  }

  return result;
};

export default sanitizeObject;
