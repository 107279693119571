import clearStore from "./clearStore";

export const DefaultCase = Symbol("DefaultCase");

export const createReducer = (declaration, initialValue) => {
  // eslint-disable-next-line default-param-last
  return (state = initialValue, action) => {
    const reducer = declaration[action.type] || declaration[DefaultCase];

    if (clearStore.match(action.type)) {
      return initialValue;
    }

    return reducer ? reducer(state, action.payload, action) : state;
  };
};
