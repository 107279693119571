import { combineReducers } from "redux";
import semantic from "../modules/semantic/reducers";
import config from "../modules/config/reducers";
import error from "../modules/error/reducers";

export default combineReducers({
  semantic,
  config,
  error,
});
