import { applyMiddleware, compose, createStore } from "redux";
import rootReducer from "./configureReducers";
import sagas from "./configureSagas";
import createSagaMiddleware from "redux-saga";
import { middleware as thunkMiddleware } from "redux-saga-thunk";
import logger from "redux-logger";

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// Add middlewares here
let middleware = [thunkMiddleware, sagaMiddleware];

if (process.env.NODE_ENV !== "production") {
  middleware = [...middleware, logger];
}

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

export default function configureStore(initialState, context = {}) {
  const services = {};

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware))
  );

  if (module.hot) {
    module.hot.accept("./configureReducers", () => {
      const nextRootReducer = require("./configureReducers");
      store.replaceReducer(nextRootReducer);
    });
  }

  store.runSagaTask = () => {
    store.sagaTask = sagaMiddleware.run(sagas, services, context);
  };

  // run the rootSaga initially
  store.runSagaTask();

  return store;
}

export const store = configureStore({});
