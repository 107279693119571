const loadFonts = (fontFaceArray) => {
  fontFaceArray.forEach((el) => {
    const otherProperties = {};
    if (el?.fontStyle) {
      otherProperties.style = el.fontStyle;
    }

    if (el?.fontWeight) {
      otherProperties.weight = el.fontWeight;
    }

    const junction_font = new FontFace(el.fontFamily, el.src, otherProperties);
    junction_font
      .load()
      .then(function (loaded_face) {
        document.fonts.add(loaded_face);
        // document.body.style.fontFamily = '"Open Sans", sans-serif';
      })
      .catch(function (error) {
        console.error(`Error loading custom font/s: ${error}`);
      });
  });
};

export default loadFonts;
