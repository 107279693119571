import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/browser";
import loadUrlParams from "./util/loadUrlParams";
// import { name, version } from "../package.json";

const { props, scenario } = loadUrlParams();
const deviceId = props.did;
const companyId = props.cid;

const ANALYTICS_URL = String(
  `${process.env.REACT_APP_endpoints_analytics}` || ""
);

if (process.env.REACT_APP_account_stage === "prod") {
  const dsn =
    "https://d3b2310b93504788b4bc70a5892a3943@o101605.ingest.sentry.io/5521793";
  Sentry.init({
    // release: `${name}@${version}`,
    release: `hi-renderer@2.0.0`,
    dsn,
    enabled: false, // set to false to temporarily disable it; doesn't prevent all overhead from Sentry instrumentation
    // environment: process.env.REACT_APP_account_stage,
  });
  Sentry.setTag("cid", companyId);
  Sentry.configureScope((scope) => {
    scope.setUser({ id: deviceId });
  });
}

const initPayload = {
  product: "stimuli",
  action: "renderer-error",
  timestamp: Date.now(),
  scenario,
};

window.onerror = (errorMsg, url, lineNumber, rowNumber, error) => {
  console.error("Global onError handler", errorMsg);
  const data = JSON.stringify({
    ...initPayload,
    ...props,
    errorMsg,
    url,
    lineNumber,
    rowNumber,
    error: error.toString(),
  });

  window.navigator.sendBeacon(ANALYTICS_URL, data);

  // Here sentry would be useful, for the part outside of ErrorBoundary; for that within, there would be double entry
  // Sentry.captureException({ error, source: "global window.onerror" });
};

window.onunhandledrejection = (e) => {
  console.error("Global onUnhadledRejection handler", e.reason);

  const data = JSON.stringify({
    ...initPayload,
    ...props,
    errorMsg: `Global window.onunhandledrejection. Reason: ${e.reason}`,
  });
  window.navigator.sendBeacon(ANALYTICS_URL, data);

  Sentry.captureException({ e, source: "global window.onunhandledrejection" });
};

function stopWheelZoom(event) {
  if (event.ctrlKey) {
    event.preventDefault();
  }
}

function stopKeyZoom(event) {
  if (
    event.ctrlKey &&
    [48, 61, 96, 107, 109, 187, 189].includes(event.keyCode)
  ) {
    event.preventDefault();
  }
}

document.addEventListener("keydown", stopKeyZoom);
document.addEventListener("mousewheel", stopWheelZoom);
document.addEventListener("DOMMouseScroll", stopWheelZoom);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
