import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import classnames from "classnames";
import { setDropdownValue } from "../modules/semantic/actions";

const Dropdown = ({ dropdownData, setCtaDisabled, template }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Disable CTA of prime if dropdown renders
    setCtaDisabled(true);
    return () => {
      setCtaDisabled(false);
    };
  }, [setCtaDisabled]);

  const handleChange = useCallback((optionId) => {
    const option = dropdownData.find(({ id }) => optionId === id);
    if (!option) return;

    dispatch(setDropdownValue(option));
    setCtaDisabled(false);
  }, [dispatch, dropdownData, setCtaDisabled]);

  // For the new template
  const [isOpen, setIsOpen] = useState(false);
  const toggling = () => setIsOpen(!isOpen);
  const [selectedOption, setSelectedOption] = useState(null);
  
  const onOptionClicked = useCallback((optionId) => {
    handleChange(optionId);
    setSelectedOption(optionId);
    setIsOpen(false);
  }, [handleChange]);

  const getLabelFromId = (optionId) => {
    return dropdownData.find((el) => el.id === optionId)?.label;
  };

  return (
    <React.Fragment>
      {template === "default" ? (
        <select className="select" onChange={(e) => handleChange(e.target.value)}>
          <option value="" disabled selected>
            {dropdownData.find((el) => el.id === "placeholder")?.label}
          </option>
          {dropdownData
            .filter((el) => el.id !== "placeholder")
            .map((option) => (
              <option key={option.id} value={option.id}>
                {option.label}
              </option>
            ))}
        </select>
      ) : (
        <div className="dropDownContainer">
          <div
            className={classnames("dropDownHeader", {
              selected: selectedOption,
            })}
            onClick={toggling}
          >
            {selectedOption
              ? getLabelFromId(selectedOption)
              : getLabelFromId("placeholder")}
          </div>
          {isOpen && (
            <div className="dropDownListContainer">
              <div className="dropDownList">
                {dropdownData
                  .filter((el) => el.id !== "placeholder")
                  .map((option) => (
                    <div
                      key={option.id}
                      value={option.id}
                      className={classnames("listItem", {
                        selected: selectedOption === option.id,
                      })}
                      onClick={() => onOptionClicked(option.id)}
                    >
                      {option.label}
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default Dropdown;
