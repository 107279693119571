import { createReducer } from "../../store/utils/createReducer";
import { setError } from "./actions";

const initialState = {
  error: false,
};

export default createReducer(
  {
    [setError]: (state) => ({
      ...state,
      error: true,
    }),
  },
  initialState
);
