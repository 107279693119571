const actionCreators = [createAction];

export function createAction(name, payloadMapper) {
  const scopedName = this?.scope ? `${this.scope}/` : "";
  const type = `@@renderer/${scopedName}${name}`;

  function actionCreator(...args) {
    const { meta, payload, restFields } = payloadMapper
      ? payloadMapper(...args)
      : { payload: args[0], meta: args[1], restFields: args[2] };
    const metaFields = meta && { meta };

    return {
      type,
      payload,
      ...metaFields,
      ...restFields,
    };
  }

  actionCreator.toString = () => `${type}`;
  actionCreator.match = (actionName) => actionName === type;
  actionCreator.bind({ displayName: type });
  return actionCreator;
}

export const scopedCreators = (scopeName) =>
  actionCreators.map((fnc) => fnc.bind({ scope: scopeName }));
