import React from "react";
import styled from "styled-components";

const Button = styled.button``;

const CTAButton = (props) => {
  const { color, index, className, children, classNameActionOverride } = props;

  return (
    <Button
      color={color}
      id={`cta-button-${index}`}
      className={`${className || "cta-button-default"} ${
        classNameActionOverride || "cta-button"
      }`}
      {...props}
    >
      {children}
    </Button>
  );
};

export default CTAButton;
