import { createReducer } from "../../store/utils/createReducer";
import { setConfig } from "./actions";
import { nanoid10Insecure } from "../../util/nanoid10Insecure";
import { setClose, resetClose } from "../semantic/actions";

const initialState = {
  startTimestamp: new Date().getTime(),
  afterCTAClickCloseTimeout: 500,
  renderId: nanoid10Insecure(),
  exitClicked: false,
};

export default createReducer(
  {
    [setConfig]: (state, payload) => ({
      ...state,
      ...payload,
    }),
    [setClose]: (state) => ({
      ...state,
      exitClicked: true,
    }),
    [resetClose]: (state) => ({
      ...state,
      exitClicked: false,
    }),
  },
  initialState
);
