import React, { useEffect, useMemo } from "react";
import { store } from "./store/configureStore";
import { Provider, useDispatch } from "react-redux";
import loadUrlParams from "./util/loadUrlParams";
import {
  getSemantic,
  setInitialProps,
  setQueryProps,
} from "./modules/semantic/actions";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import { setConfig, trackAnalytics } from "./modules/config/actions";
import { getColors } from "./util/colors";
import { RendererComponent } from "./components/RendererComponent";
import ErrorBoundary from "./components/ErrorBoundary";

const GlobalStyles = createGlobalStyle`
  html {
    margin: 0;
    padding: 0;
  }
  body {
    margin: 0;
    user-select: none;
    // Height is overwritten is PrimeContainer.js
    height: 100%;
    width: 100%;
  }
  
  body,
  html {
    background: transparent;
    will-change: transform;
    animation: fade-in 1s ease-in forwards;
    font-family: "Segoe UI", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    user-select: none;
    margin: 0;
    overflow: hidden;
  }

  button {
    cursor: pointer;
    background-color: transparent;
    border: none;
  }

  button:focus {
    outline: none;
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .emoji {
    width: 18px;
    height: 18px;
    margin-bottom: -4px;
  }
`;

const CustomApp = () => {
  const params = useMemo(() => loadUrlParams(), []);
  const dispatch = useDispatch();

  const { error, props, silent, propsString, preview, iframeId } = params;
  const { source, scenario, leafPayload } = params;

  useEffect(() => {
    if (error) {
      console.error("Error occurred while processing props");
      setTimeout(() => {
        window.parent?.postMessage(
          {
            type: "closed",
            iframeId: new URLSearchParams(location.search).get("iframeId"),
          },
          "*"
        );
        window.close();
      }, 100);
    } else {
      dispatch(setInitialProps(props));
      dispatch(setQueryProps(JSON.parse(propsString)));
      dispatch(
        setConfig({ silent, preview, iframeId, source, scenario, leafPayload })
      );
    }
  }, [
    dispatch,
    error,
    props,
    silent,
    propsString,
    preview,
    scenario,
    source,
    leafPayload,
  ]);

  useEffect(() => {
    dispatch(trackAnalytics({ action: "renderer-init" }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getSemantic(props?.semanticId));
  }, [dispatch, props]);

  return (
    <ThemeProvider theme={getColors(props?.colors)}>
      <GlobalStyles />
      <RendererComponent />
    </ThemeProvider>
  );
};

export const App = () => (
  <Provider store={store}>
    <ErrorBoundary>
      <CustomApp />
    </ErrorBoundary>
  </Provider>
);
