const enterSignReg = /(\n)./;

const textToHtml = (text = "") => {
  if (enterSignReg.test(text)) {
    const [, enterSign] = enterSignReg.exec(text) || [];
    return text.replace(new RegExp(enterSign, "gm"), "<br>");
  }

  return text;
};

export default textToHtml;
