import { useCallback } from "react";
import {
  selectDropdownValue,
  selectSemantic,
} from "../modules/semantic/selectors";
import { useSelector } from "react-redux";

const useButtonProps = () => {
  const dropdownValue = useSelector(selectDropdownValue);
  const {
    cta1Type,
    cta1Target,
    cta1Value,
    cta1NewWindow,
    cta1Auth,
    cta2Type,
    cta2Target,
    cta2Value,
    cta2NewWindow,
    cta2Auth,
  } = useSelector(selectSemantic);

  return useCallback(
    (button) => {
      if (button.id === "cta-button-first") {
        return {
          ctaType: dropdownValue?.ctaType ?? cta1Type,
          ctaTarget: dropdownValue?.ctaTarget ?? cta1Target,
          ctaValue: dropdownValue?.value ?? cta1Value,
          shouldOpenInNewWindow: dropdownValue?.ctaNewWindow ?? cta1NewWindow,
          shouldAddAuthParams: dropdownValue?.ctaAuth ?? cta1Auth,
          buttonName: "cta-button-first",
        };
      }

      return {
        ctaType: dropdownValue?.ctaType ?? cta2Type,
        ctaTarget: dropdownValue?.ctaTarget ?? cta2Target,
        ctaValue: dropdownValue?.value ?? cta2Value,
        shouldOpenInNewWindow: dropdownValue?.ctaNewWindow ?? cta2NewWindow,
        shouldAddAuthParams: dropdownValue?.ctaAuth ?? cta2Auth,
        buttonName: "cta-button-second",
      };
    },
    [
      dropdownValue,
      cta1Type,
      cta1Target,
      cta1Value,
      cta1NewWindow,
      cta1Auth,
      cta2Type,
      cta2Target,
      cta2Value,
      cta2NewWindow,
      cta2Auth,
    ]
  );
};

export default useButtonProps;
