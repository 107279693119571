/* eslint-disable */
import React, { useCallback, useState, useEffect, useMemo } from "react";
import classnames from "classnames";
import rehypeRaw from "rehype-raw";
import styled, { createGlobalStyle } from "styled-components";

import HiPrimeMiniIcon from "./icons/HiPrimeMiniIcon";
import PrimeMiniClose from "./icons/PrimeMiniClose";
import { useDispatch, useSelector } from "react-redux";
import { trackAnalytics } from "../modules/config/actions";
import { primeMiniClose } from "../modules/semantic/actions";
import useComponentLoaded from "../hooks/useComponentLoaded";
import { selectConfig } from "../modules/config/selectors";
import { ShakeAnimationCSS } from "./animations";
import { selectSemantic } from "../modules/semantic/selectors";
import ReactMarkdown from "react-markdown";
import textToHtml from "../util/textToHtml";
import { tmpSemanticPreviewTemplates } from "./tmpTemplates";
import {
  LEGACY_TEMPLATEFAMILY_ID,
  NO_PREVIEW_ID,
  PREVIEW_LEGACY,
} from "../constConfig/config.js";
import templatePreProcessStyles from "../util/templatePreProcessStyles";

const WrappingContainer = styled.div`
  ${({ animation }) => (animation ? ShakeAnimationCSS : "")}
`;

const providePreviewStyleObject = function (
  template,
  templateColors,
  templateFonts
) {
  // preprocessing to templateCard

  // fonts, css colors, svg colors
  templatePreProcessStyles(template, templateColors, templateFonts, "preview");

  return template.previewStyle;
};

const PrimeMiniStyles =
  // could also receive props (props) =>
  createGlobalStyle(({ template, templateColors, templateFonts }) =>
    providePreviewStyleObject(template, templateColors, templateFonts)
  );

const PrimeMini = ({ children, type, isAnimationEnabled }) => {
  const {
    flowId,
    journeyId,
    journey,
    miniTipAutoProgress,
    miniTipAutoProgressDelay,
    miniTipAutoProgressOnlyIfIgnored,
    isPrimeMiniIgnoredTimes,
    templateFamilyId,
    semanticTemplateId,
    semanticPreview,
    templateColors,
  } = useSelector(selectSemantic);

  const [clicked, setClicked] = useState(false);
  const [closeClicked, setCloseClicked] = useState(false);
  const [autoProgress, setAutoProgress] = useState(false);
  const { source, iframeId } = useSelector(selectConfig);
  const [showPrimeMini, setShowPrimeMini] = useState(true);
  const isAnyCtaClicked = useMemo(
    () => clicked || closeClicked,
    [clicked, closeClicked]
  );
  const dispatch = useDispatch();

  const handleCTAClick = useCallback(async () => {
    dispatch(
      trackAnalytics({
        action: closeClicked
          ? type === "PRIME"
            ? "prime-mini-click-after-close"
            : "nudge-mini-click-after-close"
          : type === "PRIME"
          ? "prime-mini-clicked"
          : "nudge-mini-clicked",
      })
    );

    if (!isAnyCtaClicked) {
      setShowPrimeMini(false);
    }

    setClicked(true);
  }, [dispatch, setClicked, isAnyCtaClicked, closeClicked]);

  const handleAutoProgress = useCallback(async () => {
    if (!autoProgress && !clicked && !closeClicked) {
      setAutoProgress(true);
      dispatch(
        trackAnalytics({
          action: closeClicked
            ? type === "PRIME"
              ? "prime-mini-click-after-close"
              : "nudge-mini-click-after-close"
            : type === "PRIME"
            ? "prime-mini-auto-progress"
            : "nudge-mini-auto-progress",
          miniTipAutoProgressDelay: miniTipAutoProgressDelay
            ? miniTipAutoProgressDelay
            : 0,
        })
      );

      setShowPrimeMini(false);
    }
  }, [
    autoProgress,
    setAutoProgress,
    clicked,
    closeClicked,
    dispatch,
    setShowPrimeMini,
    miniTipAutoProgressDelay,
    setShowPrimeMini,
  ]);

  const handleCloseClick = useCallback(async () => {
    dispatch(
      trackAnalytics({
        action:
          type === "PRIME"
            ? "prime-mini-close-click"
            : "nudge-mini-close-click",
      })
    );
    if (!closeClicked) {
      dispatch(primeMiniClose());
    }

    setCloseClicked(true);
  }, [dispatch, setCloseClicked, closeClicked]);

  // use the custom template if:
  // not the legacy template family and a semanticTemplateId and semanticPreviewTemplateId are specified
  // and semanticPreviewTemplateId is available

  const familyId = templateFamilyId
    ? templateFamilyId
    : LEGACY_TEMPLATEFAMILY_ID;

  const previewTemplate =
    familyId &&
    semanticTemplateId &&
    tmpSemanticPreviewTemplates?.[semanticPreview.semanticPreviewTemplateId]
      ? semanticPreview.semanticPreviewTemplateId
      : PREVIEW_LEGACY;

  const customSemanticPreviewTemplate =
    tmpSemanticPreviewTemplates?.[previewTemplate] ?? null;

  useComponentLoaded({
    loadedActionName:
      type === "PRIME" ? "prime-mini-window-load" : "nudge-mini-window-load",
    width: customSemanticPreviewTemplate?.windowInfo?.totalWidth
      ? customSemanticPreviewTemplate.windowInfo.totalWidth
      : 78,
    height: customSemanticPreviewTemplate?.windowInfo?.totalHeight
      ? customSemanticPreviewTemplate.windowInfo.totalHeight
      : 72,
    semanticsHistoryMeta: {
      isPrimeMini: true,
      flowId,
      journeyId,
      journeyName: journey?.name,
      interactionOn: null,
    },
  });

  useEffect(() => {
    var autoProgressTimer = null;
    if (
      ((miniTipAutoProgress === true && !miniTipAutoProgressOnlyIfIgnored) ||
        // condition on ignored times, in semantic
        (miniTipAutoProgress === true &&
          miniTipAutoProgressOnlyIfIgnored === true &&
          isPrimeMiniIgnoredTimes === true) ||
        // similarly satisfied requirements from journey config, in case semantic does not have more specific miniTipAutoProgress config
        (!miniTipAutoProgress &&
          journey?.miniTipAutoProgress === true &&
          !journey?.miniTipAutoProgressOnlyIfIgnored) ||
        (!miniTipAutoProgress &&
          journey?.miniTipAutoProgress === true &&
          journey?.miniTipAutoProgressOnlyIfIgnored === true &&
          journey?.isPrimeMiniIgnoredTimes === true)) &&
      !autoProgress &&
      !clicked &&
      !closeClicked
    ) {
      const delay = miniTipAutoProgress
        ? miniTipAutoProgressDelay
        : journey.miniTipAutoProgressDelay;
      if (delay > 0) {
        autoProgressTimer = setTimeout(() => {
          handleAutoProgress();
        }, delay * 1000);
      } else {
        // for example, if delay is zero
        handleAutoProgress();
      }
    }

    return () => {
      clearTimeout(autoProgressTimer);
    };
  }, [handleAutoProgress]);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(
        trackAnalytics({
          action:
            type === "PRIME"
              ? "renderer-prime-mini-timeout"
              : "renderer-nudge-mini-timeout",
        })
      );
      setTimeout(() => {
        window.parent?.postMessage({ type: "closed", iframeId }, "*");
        window.close();
      }, 500);
    }, 60 * 60 * 8 * 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [dispatch]);

  if (showPrimeMini === false) {
    return children;
  }

  return (
    <WrappingContainer
      className={classnames("prime-mini", {
        loading: isAnyCtaClicked,
      })}
      style={{ opacity: showPrimeMini ? 1 : 0 }}
      animation={isAnimationEnabled}
    >
      <PrimeMiniStyles
        template={customSemanticPreviewTemplate}
        templateColors={templateColors}
        templateFonts={semanticPreview} // Currently titlefontsize, titlefontfamily are inside semanticPreview
      />
      <div onClick={handleCTAClick} className="prime-mini__clickable-area">
        <div className="prime-mini__card">
          <div className="prime-mini__card-loader" />
          {previewTemplate === PREVIEW_LEGACY && (
            <>
              <HiPrimeMiniIcon
                className="prime-mini__card-hilogo"
                width={28}
                height={28}
              />
              {/* ch21471 */}
              <div className="prime-mini__card-flow-length">1</div>
            </>
          )}
          {previewTemplate !== NO_PREVIEW_ID &&
            previewTemplate !== PREVIEW_LEGACY && (
              <div className="content">
                <h2 className="title">
                  <ReactMarkdown
                    className="markdown"
                    rehypePlugins={[rehypeRaw]}
                    children={textToHtml(semanticPreview?.title)}
                  />
                </h2>
                {previewTemplate?.allowedCustomization?.[
                  "semanticPreview.text"
                ] &&
                  Boolean(semanticPreview.text) && (
                    <div className="text">
                      <ReactMarkdown
                        className="markdown"
                        rehypePlugins={[rehypeRaw]}
                        children={textToHtml(semanticPreview?.text)}
                      />
                    </div>
                  )}
              </div>
            )}
          <div id="prime-mini__card-extra1"></div>
          <div id="prime-mini__card-extra2"></div>
          <div id="prime-mini__card-extra3"></div>
        </div>
      </div>
      <div
        style={{
          visibility:
            !source || (source && source !== "diary") ? "visible" : "hidden",
        }}
        onClick={handleCloseClick}
        id="prime-mini__close"
      >
        {previewTemplate === PREVIEW_LEGACY ? (
          <PrimeMiniClose />
        ) : (
          <div id="closeBtnContent"></div>
        )}
      </div>
    </WrappingContainer>
  );
};

export default PrimeMini;
