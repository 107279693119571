import React from "react";

const HiPrimeMiniIcon = ({ className, width = 32, height = 32 }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.0925 7.23325C29.1048 7.23325 29.9555 6.88577 30.6444 6.19081C31.3615 5.48167 31.72 4.63779 31.72 3.65917C31.72 2.65219 31.3615 1.79413 30.6444 1.08499C29.9274 0.361662 29.0767 0 28.0925 0C27.1224 0 26.2858 0.361662 25.5828 1.08499C24.8938 1.77995 24.5493 2.63801 24.5493 3.65917C24.5493 4.65197 24.8938 5.49585 25.5828 6.19081C26.2717 6.88577 27.1083 7.23325 28.0925 7.23325ZM5.69437 31.72V20.6149C5.69437 18.8846 6.20054 17.5017 7.21287 16.4664C8.25332 15.4169 9.47656 14.8921 10.8826 14.8921C12.162 14.8921 13.1568 15.2467 13.8668 15.9558C14.5769 16.6649 14.9319 17.7074 14.9319 19.0831V31.72H20.6474V17.0408C20.6474 14.5872 19.9444 12.7363 18.5383 11.4882C17.1183 10.2259 15.2272 9.5948 12.8651 9.5948C11.459 9.5948 10.1022 9.89264 8.79464 10.4883C7.48704 11.084 6.45362 11.8711 5.69437 12.8498V0.340499H0V31.72H5.69437ZM30.9816 10.1903V31.7198H25.2872V10.1903H30.9816Z"
      fill="#94f1b2"
    />
  </svg>
);

export default HiPrimeMiniIcon;
