import React from "react";

const PrimeMiniClose = ({ width = 24, height = 24, className }) => (
  <svg
    viewBox="0 0 24 24"
    width={width}
    height={height}
    className={className}
    fill="none"
  >
    <circle cx="12" cy="12" r="12" fill="#515151" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.25894 14.6729C7.96372 14.9681 7.96372 15.4468 8.25894 15.742C8.55416 16.0372 9.0328 16.0372 9.32802 15.742L12.0004 13.0696L14.6729 15.7422C14.9681 16.0374 15.4468 16.0374 15.742 15.7422C16.0372 15.4469 16.0372 14.9683 15.742 14.6731L13.0694 12.0006L15.7425 9.32748C16.0377 9.03226 16.0377 8.55362 15.7425 8.2584C15.4473 7.96318 14.9687 7.96318 14.6734 8.2584L12.0004 10.9315L9.32748 8.25859C9.03226 7.96337 8.55362 7.96337 8.2584 8.25859C7.96318 8.55381 7.96318 9.03246 8.2584 9.32767L10.9313 12.0006L8.25894 14.6729Z"
      fill="white"
    />
  </svg>
);

export default PrimeMiniClose;
