import { takeLatest, select } from "redux-saga/effects";
import { trackAnalytics, attributeGamePoints } from "./actions";
import { selectConfig } from "./selectors";
import {
  selectDropdownValue,
  selectQueryProps,
  selectSemantic,
} from "../semantic/selectors";
import { nanoid10Insecure } from "../../util/nanoid10Insecure";
import * as Sentry from "@sentry/browser";

function* onTrackAnalytics({ payload: { action, additionalFields = {} } }) {
  const config = yield select(selectConfig);
  const semanticWithProps = yield select(selectSemantic);
  const props = yield select(selectQueryProps);
  const dropdownValue = yield select(selectDropdownValue);

  const { tagsArray, image, ...semantic } = semanticWithProps || {};

  try {
    if (config.silent && !action.endsWith("-diary")) {
      console.info("Silent mode. Skipping trackAnalytics");
      return;
    }

    if (
      action.includes("window-load") &&
      config?.scenario === "1readagain" &&
      semantic?.isFirstInFlow
    ) {
      // for firstInFlow of 1readagain, just window-load event is skipped
      return;
    }

    if (!action) {
      console.error("trackAnalytics requires an action property");
      return;
    }

    const trackingId = nanoid10Insecure();
    const timestamp = Date.now();
    const secondsSinceOpen = Math.floor(
      (timestamp - config.startTimestamp) / 1000
    );

    if (action.includes("window-load")) {
      const resolution = [window.screen?.width, window.screen?.height]
        .map((s) => s * (window.devicePixelRatio || 1))
        .join("x");
      const screenInfo = {
        availTop: window.screen?.availTop,
        availLeft: window.screen?.availLeft,
        availHeight: window.screen?.availHeight,
        availWidth: window.screen?.availWidth,
        height: window.screen?.height,
        width: window.screen?.width,
        orientation: window.screen?.orientation?.type ?? "unknown",
        devicePixelRatio: window.devicePixelRatio,
      };
      additionalFields.screenInfo = screenInfo;
      additionalFields.resolution = resolution;
    }

    if (config?.leafPayload?.delayReason) {
      additionalFields.delayReason = config.leafPayload.delayReason;
    }

    if (config?.leafPayload?.delayedDisplayTime) {
      additionalFields.delayedDisplayTime =
        config.leafPayload.delayedDisplayTime;
    }

    if (config?.leafPayload?.delayedDisplayTimeSinceCallEnd) {
      additionalFields.delayedDisplayTimeSinceCallEnd =
        config.leafPayload.delayedDisplayTimeSinceCallEnd;
    }

    const payload = {
      // everything that is available in the URL
      ...props,
      type: semantic?.type,
      stimulusId: semantic?.stimulusId,
      // plus analytics metadata
      product: "stimuli",
      action,
      scenario: config?.scenario || config?.leafPayload?.scenario,
      timestamp,
      renderId: config.renderId,
      trackingId,
      eid: props?.eid,
      // plus values from input components
      tipinputvalue: dropdownValue?.value ?? null,
      secondsSinceOpen,
      // plus a snapshot of the current
      // properties of the semantic being displayed
      flowId: semantic?.flowId,
      ...additionalFields,
    };

    const data = JSON.stringify(payload);
    console.log("trackAnalytics calling sendBeacon():", payload?.action); // data

    const beaconQueued = window.navigator.sendBeacon(
      String(`${process.env.REACT_APP_endpoints_analytics}` || ""),
      data
    );

    if (beaconQueued) {
      console.log("sendBeacon(): queued");
    } else {
      Sentry.captureMessage("sendBeacon(): cannot queue the analytics request");
      console.error("sendBeacon(): cannot queue the analytics request");
    }
  } catch (e) {
    Sentry.captureException(e);
    console.error("Analytics request failed", e);
  }
}

const ASSIGNPOINTS_ENDPOINT =
  process.env?.REACT_APP_service_hi_user_dashboard_api_save_gamification_event;

function* onAttributeGamePoints({ payload: { action } }) {
  // console.log("AttributingGamePoints");

  if (!ASSIGNPOINTS_ENDPOINT) {
    console.error("ASSIGNPOINTS_ENDPOINT is not defined");
    return;
  }

  const config = yield select(selectConfig);
  const semanticWithProps = yield select(selectSemantic) || {};
  const props = yield select(selectQueryProps);

  try {
    if (!semanticWithProps.isGamificationEnabled) {
      console.warn("Gamification is not enabled");
      return;
    }

    if (config.silent && !action.endsWith("-diary")) {
      console.info("Silent mode. Skipping gamification points");
      return;
    }

    if (!action) {
      console.error("attributeGamePoints requires an action property");
      return;
    }

    const payload = {
      product: "renderer",
      context: semanticWithProps?.flowId, // flowId
      cid: props.cid ?? null,
      did: props.did ?? null,
      eid: props.eid ?? null,
      // initialize also the rest of the fields
      gid: null,
      gentime: Date.now(),
      eventtype: semanticWithProps?.isFirstInFlow
        ? "clickctafirst"
        : "clickctasubsequent",
      resourceid: props.semanticId ?? null,
    };

    const isBeaconDataQueued = window.navigator.sendBeacon(
      ASSIGNPOINTS_ENDPOINT,
      JSON.stringify(payload)
    );

    if (!isBeaconDataQueued) {
      console.error("sendBeacon(): Can't queue the gamification data");
      return;
    }

    console.log("sendBeacon(): Successfully queued the gamification data");
  } catch (e) {
    Sentry.captureException(e);
    console.error("Gamification request failed", e);
  }
}

export default function* () {
  yield takeLatest(trackAnalytics, onTrackAnalytics);
  yield takeLatest(attributeGamePoints, onAttributeGamePoints);
}
