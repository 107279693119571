import { createReducer } from "../../store/utils/createReducer";
import {
  setInitialProps,
  setSemantic,
  setQueryProps,
  setDropdownValue,
} from "./actions";

const initialState = {
  initialProps: null,
  queryProps: null,
  semantic: null,
  dropdownValue: null,
};

export default createReducer(
  {
    [setQueryProps]: (state, payload) => ({
      ...state,
      queryProps: { ...payload },
    }),
    [setInitialProps]: (state, payload) => ({
      ...state,
      initialProps: { ...payload },
    }),
    [setSemantic]: (state, payload) => ({
      ...state,
      semantic: { ...payload },
    }),
    [setDropdownValue]: (state, payload) => ({
      ...state,
      dropdownValue: payload,
    }),
  },
  initialState
);
